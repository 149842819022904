.page {
  position: relative;
  max-width: 100%;
  margin: 0 auto;

  [data-header-desktop] & {
    padding-top: $header-height;
  }

  [data-header-desktop=normal] & {
    padding-top: 0;
  }

  @include blur;
}

@import "_home";
